.Instructions {
  font-size: 1.125rem;
  color: #fff;
  width: 100%;
  height: 100%;
  line-height: 1.8;
  transition: all 0.3s ease;
  background-color: #22206a;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.Instructions_content {
  margin: 1.5rem 0;
  padding: 0 1.25rem;
  min-height: 50px;
}

.Instructions_content, .second-attempt {
  line-height: 1.8;
  color: #fff;
}

.second-attempt {
  display: block;
  margin-top: 0.5rem;
  font-size: 1rem;
}

.second-attempt b {
  color: #33cc66;
  font-weight: 600;
}

.Instructions_divider {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  width: 100%;
  margin: 1.5rem 0;
}

.fade-in {
  animation: fadeIn 0.5s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (max-width: 768px) {
  .Instructions {
    font-size: 1rem;
  }
  
  .Instructions_content {
    padding: 0 1rem;
  }
}

