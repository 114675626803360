.Head {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  background-color: #22206a;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  min-height: fit-content;
}

.Head > div {
  font-size: 1.25rem;
  color: #fff;
  font-weight: 600;
  letter-spacing: 0.01em;
}

.Head > button {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  cursor: pointer;
  padding: 0.75rem 1rem;
  font-size: 0.875rem;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  /* Removed transition to ensure immediate response */
}

.Head > button:hover {
  background-color: rgba(255, 255, 255, 0.25);
  border-color: rgba(255, 255, 255, 0.4);
}

.Head > button:focus {
  outline: 2px solid #33cc66;
  outline-offset: 2px;
}

.Head > button:active {
  transform: scale(0.95);
}

@media screen and (max-width: 768px) {
  .Head {
    padding: 1rem;
  }
  
  .Head > div {
    font-size: 1.125rem;
  }
  
  .Head > button {
    padding: 0.5rem 0.75rem;
  }
}