a {
  text-decoration: none;
  color: #fff;
}

.zp-trigger-wrapper {
  width: 100% !important;
  height: 100% !important;
  border-radius: 5px !important;
  background-color: #22206a;
  display: grid;
  grid-template-columns: 1fr 0.1fr 1fr;
  overflow: hidden !important;
  min-height: fit-content !important;
}

.zp-trigger-wrapper--mobile {
  width: 100% !important;
  height: 100% !important;
  border-radius: 5px !important;
  background-color: #22206a;
  display: grid;
  grid-template-rows: 1fr 0.05fr 1fr;
  overflow: hidden !important;
  min-height: fit-content !important;
}

div.zp-brand-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 2px;
}

div.zp-brand-box--mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10px;
}

div.zp-brand-box>span {
  color: #fff;
  font-size: xx-small;
  font-weight: bold;
  margin-bottom: 10px;
}

button#checkbox {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
}

#_checkbox {
  display: none;
}

label {
  position: relative;
  width: 120px;
  height: 30px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s ease transform, 0.2s ease background-color;
  overflow: hidden;
  z-index: 1;
  border: 2px solid #33cc66;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  color: var(--text-color-contrast);
}

label.--mobile {
  width: 40px;
  height: 40px;
  border: 2px solid rgba(0, 0, 0, 0.44);
  margin-left: 15px;
}


label:active {
  transform: scale(0.9);
}

label.success>#status {
  color: #fff;
}

label.success>#status_mark {
  width: 30px;
  height: 25px;
  margin-bottom: 15px;
  margin-left: 2px;
  transform: rotateZ(-40deg);
}

label.success>#status_mark:before,
label.success>#status_mark:after {
  content: "";
  position: absolute;
  background-color: #fff;
  border-radius: 1px;
  opacity: 0;
  transition: 0.2s ease transform, 0.2s ease opacity;
}

label.success>#status_mark:before {
  left: 0;
  bottom: 0;
  width: 5px;
  height: 15px;
  transform: translateY(-68px);
}

label.success>#status_mark:after {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
  transform: translateX(78px);
}

label.success {
  background-color: #33cc66;
}

label.success:before {
  width: 0;
  height: 0;
}

label.success>#status_mark:before,
label.success>#status_mark:after {
  transform: translate(0);
  opacity: 1;
}

label.failure {
  background-color: #ff3300;
  border-color: #ff3300;
  color: #fff;
}

label.failure>#status_mark {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-left: 5px;
  background:
    linear-gradient(45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 43%, #000 45%, #000 55%, rgba(0, 0, 0, 0) 57%, rgba(0, 0, 0, 0) 100%),
    linear-gradient(135deg, #ff3300 0%, #ff3300 43%, #000 45%, #000 55%, #ff3300 57%, #ff3300 100%);
}

div#notabot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 2px;
}

div#notabot.--mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 2px;
  margin-top: 15px;
  margin-bottom: 15px;
}

div#notabot>span {
  color: #fff;
  font-size: small;
  font-weight: bold;
  margin-top: 15px;
}

div#notabot>span.--mobile {
  color: white;
  font-size: small;
  font-weight: bold;
  margin-top: 0;
  margin-right: 15px;
}

.logo-wrapper {
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-wrapper>img {
  width: 90%;
  height: auto;
}

.logo-wrapper>img.--mobile {
  width: 75%;
  height: auto;
}

.vertical_line {
  border-left: 1px solid grey;
  height: 80%;
  vertical-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.horizontal_line {
  border-top: 1px solid grey;
  width: 90%;
  vertical-align: center;
}

.horizontal_line--mobile {
  border-top: 1px solid grey;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}

.checkbox-label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: var(--text-color-contrast);
}

.checkbox-label.--mobile {
  display: none;
}