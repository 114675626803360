.zp-loader {
  text-align: center !important;
  height: 100% !important;
  width: 100% !important;
  background-color: var(--primary-bg) !important;
  border-radius: 5px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  overflow: hidden;
}

.zp-loader-container.success {
  transform: translateY(9px) !important;
}

.zp-loader-container span {
  display: inline-block;
  vertical-align: middle;
  min-width: 18px !important;
  min-height: 18px !important;
  width: 18px;
  height: 18px;
  margin: 50px auto;
  opacity: 0.9;
  border-radius: 50% !important;
  border: 0;
  transition: all 0.3s ease-out;
  animation: zp-loader 1.2s infinite alternate;
}

.zp-loader-container span.loading:nth-of-type(1) {
  background: #9933dd !important;
  animation-delay: 0.2s !important;
}

.zp-loader-container span.loading:nth-of-type(2) {
  background: #dd2255 !important;
  animation-delay: 0.4s !important;
}

.zp-loader-container span.loading:nth-of-type(3) {
  background: #ffbb00 !important;
  animation-delay: 0.6s !important;
}

.zp-loader-container span.loading:nth-of-type(4) {
  background: var(--success-color) !important;
  animation-delay: 0.8s !important;
}

.zp-loader-container span.loading:nth-of-type(5) {
  background: #22bbff !important;
  animation-delay: 1.0s !important;
}

.zp-loader-container span.success {
  width: 18px !important;
  height: 18px !important;
  background: var(--success-color) !important;
  opacity: 1 !important;
  animation: none;
}

.zp-loader-container span.failure {
  width: 18px !important;
  height: 18px !important;
  background: var(--error-color) !important;
  opacity: 1 !important;
  animation: none;
}

.zp-loader-container span.success:nth-of-type(2) {
  -webkit-transform: translateY(18px);
  -ms-transform: translateY(18px);
  transform: translateY(18px);
}
.zp-loader-container span.success:nth-of-type(4) {
  -webkit-transform: translateY(-18px);
  -ms-transform: translateY(-18px);
  transform: translateY(-18px);
}
.zp-loader-container span.success:nth-of-type(5) {
  -webkit-transform: translateY(-36px);
  -ms-transform: translateY(-36px);
  transform: translateY(-36px);
}

.zp-loader-container span.failure:nth-of-type(1) {
  -webkit-transform: translateY(-18px) translateX(18px);
  -ms-transform: translateY(-18px) translateX(18px);
  transform: translateY(-18px) translateX(18px);
}
.zp-loader-container span.failure:nth-of-type(2) {
  -webkit-transform: translateY(18px);
  -ms-transform: translateY(18px);
  transform: translateY(18px);
}
.zp-loader-container span.failure:nth-of-type(4) {
  -webkit-transform: translateY(-18px);
  -ms-transform: translateY(-18px);
  transform: translateY(-18px);
}
.zp-loader-container span.failure:nth-of-type(5) {
  -webkit-transform: translateY(18px) translateX(-18px);
  -ms-transform: translateY(18px) translateX(-18px);
  transform: translateY(18px) translateX(-18px);
}

@keyframes zp-loader {
  0% {
    width: 18px;
    height: 18px;
    opacity: 0.9;
  }
  100% {
    width: 30px;
    height: 30px;
    opacity: 1;
  }
}
@-webkit-keyframes zp-loader {
  0% {
    width: 18px;
    height: 18px;
    opacity: 0.8;
  }
  100% {
    width: 30px;
    height: 30px;
    opacity: 1;
  }
}
