.zp-pzz-desktop-container {
  width: 400px !important;
  height: 400px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  transition: all 0.5s ease-in-out !important;
  background-color: var(--primary-bg) !important;
  border-radius: 5px !important;
  padding: 1rem !important;
}

.zp-pzz-mobile-container {
  width: 300px !important;
  height: 300px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  transition: all 0.5s ease-in-out !important;
  background-color: var(--primary-bg) !important;
  border-radius: 5px !important;
  padding: 0.5rem !important;
}

.zp-pzz-canvas {
  width: 100% !important;
  height: 100% !important;
  border-radius: 3px !important;
}