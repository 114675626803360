.flex {
  display: flex;
  flex-direction: column;
}

#zp-challenge-container.bg {
  background-color: var(--primary-bg);
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: space-evenly;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.table {
  width: 100%;
  height: 20px;
  background-color: var(--primary-bg);
  color: #fff;
  display: table;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  margin: 0.5rem 0;
}

.cell {
  display: table-cell;
  vertical-align: middle;
  margin: auto;
  text-align: center;
  color: #fff;
  font-size: 1rem;
  padding: 0.5rem;
}

@media screen and (max-width: 768px) {
  .table {
    font-size: 0.9rem;
  }

  .cell {
    padding: 0.25rem;
  }
}