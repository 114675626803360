.wrapper {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  background-color: #22206a;
  border-radius: 5px;
  padding: 1rem;
  color: #fff;
}

.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: #22206a;
  color: #fff;
  padding: 2rem;
}

.container a {
  color: #33cc66;
  text-decoration: underline;
  transition: color 0.3s ease;
}

.container a:hover {
  color: #40dd75;
}

.container a:focus {
  outline: 2px solid #33cc66;
  outline-offset: 2px;
}
