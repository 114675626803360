#root {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.zp-container {
  border-radius: 5px !important;
  display: flex !important;
  /* align-items: center !important; */
  justify-content: flex-start !important;
  position: relative !important;
  transition: all 0.3s ease-out !important;
  animation: fadein 0.5s;
}

.zp-container.bg {
  background-color: var(--primary-bg) !important;
}

.zp-container-dimensions-width-start {
  width: 300px !important;
}

.zp-container-dimensions-width-help {
  width: 300px !important;
}

.zp-container-dimensions-height-start {
  height: 100px !important;
}

.zp-container-dimensions-height-start--mobile {
  height: 150px !important;
}

.zp-container-dimensions-height-help {
  height: 200px !important;
}

.zp-container-dimensions-width-challenge {
  width: 100vw !important;
}

.zp-container-dimensions-height-challenge {
  height: 100vh !important;
}

.zp-container-dimensions-height-instructions {
  height: 150px !important;
}

.zp-container-dimensions-width-instructions {
  width: 400px !important;
}

.zp-container-desktop-dimensions-height-instructions--mobile {
  height: 150px !important;
}

.zp-container-dimensions-width-instructions--mobile {
  width: 300px !important;
}

.Container[view="instructions"] {
  background-color: var(--primary-bg);
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  border: 1px solid var(--border-color);
}

@media (orientation: landscape) {
  .zp-container-dimensions-height-challenge {
    height: 100vh !important;
    max-height: 100vh !important;
  }

  .zp-container-dimensions-width-challenge {
    width: 100vw !important;
  }
}

@media screen and (orientation:landscape) and (min-width: 319px) and (max-width: 950px) {

  #zp-challenge-container {
    flex-direction: row;
    gap: 1rem;
    padding: 0.5rem;
  }

  .Instructions_divider {
    display: none;
  }
}

@media (orientation: portrait) {
  .zp-container-dimensions-height-challenge {
    height: 100svh !important;
    max-height: 100svh !important;
  }

  .zp-container-dimensions-width-challenge {
    width: 100svw !important;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}